import { regexToString } from './regex';

export const capitalizeFirstChar = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

const baseUrlRegex = /(https?:\/\/)?(www\.)?([\w-]+\.)+[\w-]{2,}(\/[\w.,@?^=%&:/~+#-]*)?/;
export const urlRegex = new RegExp(`^${regexToString(baseUrlRegex)}$`);
export const globalUrlRegex = new RegExp(regexToString(baseUrlRegex), 'g');

export const isUrl = (str: string) => urlRegex.test(str);

export const urlsToMdLinks = (str: string) =>
  str.replace(globalUrlRegex, (url) => {
    const adjustedUrl = adjustUrl(url);
    return `[${url}](${adjustedUrl})`;
  });

export const urlsToHtmlATags = (str: string) =>
  str.replace(globalUrlRegex, (url) => {
    const adjustedUrl = adjustUrl(url);
    return `<a href="${adjustedUrl}" target="_blank">${adjustedUrl}</a>`;
  });

export const findUrlInString = (str: string) => (str.match(globalUrlRegex) ?? [])[0];

export const findUrlsInString = (str: string) => str.match(globalUrlRegex) ?? [];

export const adjustUrl = (url: string) => (/^(https?:\/\/)/.test(url) ? url : `https://${url}`);

export const isEmail = (str: string): boolean =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    str
  );
